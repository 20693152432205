/**
  * These colours correspond to the ones provided in the designs here:
  * https://xd.adobe.com/view/0972caea-ce98-4d8c-977f-25240872dad0-5c21/screen/3c1d8987-e133-402f-999e-f34df61870e8/
  * They are mapped to legacy color system in ChappsCommonAngularLibraryModule
  * as best as possible. If no legacy color can be matched the color is hard
  * coded from the design. The custom properties can then be overwritten
  * through the BusinessStyleService.
 */
:root {
  --base-color-1: var(--primary-color);
  --base-color-2: #c0bed4;
  --accent-1: var(--secondary-color);
  --accent-2: #faa61a;
  --progress-1: #aabcf2;
  --progress-2: #f7f9fc;
  --card-color: #f9f9fd;
  --chevron-color: #7b86aa;
  --notification-color: #dc004e;
  --menu-text: #445180;
}
