/**
  * These colours correspond to the ones provided in the designs here:
  * https://xd.adobe.com/view/0972caea-ce98-4d8c-977f-25240872dad0-5c21/screen/3c1d8987-e133-402f-999e-f34df61870e8/
  * They are mapped to legacy color system in ChappsCommonAngularLibraryModule
  * as best as possible. If no legacy color can be matched the color is hard
  * coded from the design. The custom properties can then be overwritten
  * through the BusinessStyleService.
 */
:root {
  --base-color-1: var(--primary-color);
  --base-color-2: #c0bed4;
  --accent-1: var(--secondary-color);
  --accent-2: #faa61a;
  --progress-1: #aabcf2;
  --progress-2: #f7f9fc;
  --card-color: #f9f9fd;
  --chevron-color: #7b86aa;
  --notification-color: #dc004e;
  --menu-text: #445180;
}

:root {
  --signInBackground: url("../../assets/background.jpg");
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-size-09 {
  font-size: 0.9rem !important;
}

.font-size-08 {
  font-size: 0.8rem !important;
}

p {
  font-weight: 400;
  font-size: 0.9rem;
}

.no-wrap {
  white-space: nowrap;
}

.trash-icon-in-table {
  position: relative;
  top: -1px;
}

.svg-black .cls-1 {
  fill: black !important;
}

/*.svg-secondary-text {
  .cls-1, path {
    fill: white !important;
  }
}*/
html, body {
  background-color: white;
  margin: 0;
  padding: 0;
}

@media only screen and (min-width: 960px) {
  html, body {
    background-color: white;
  }
}
.custom-navbar {
  background-color: inherit;
}

.clickable {
  cursor: pointer;
}

.no-clickable {
  cursor: not-allowed;
}

.page-spinner {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.75;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200;
}

.table-no-first-border tbody tr:first-child td {
  border-top: none;
}

.table-no-border tbody tr td {
  border: none;
}

.opacity-25 {
  opacity: 0.5;
}

.truncate-ellipsis {
  display: table;
  table-layout: fixed;
  width: 100%;
  white-space: nowrap;
}

.truncate-ellipsis > * {
  display: table-cell;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pre-wrap {
  white-space: pre-wrap;
}

.ng-select .ng-select-container {
  border: none !important;
  border-radius: 10px;
  background-color: var(--card-color) !important;
  color: #717171;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #717171 !important;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  color: #001659;
}

.ng-select.ng-valid.ng-touched.chapps-select .ng-select-container {
  border-bottom: none !important;
  box-shadow: none !important;
}

.image-placeholder {
  background-color: rgba(57, 57, 191, 0.03);
  border: none;
  border-radius: 10px;
  padding: 0;
}
.image-placeholder > div {
  background-color: rgba(57, 57, 191, 0.03) !important;
  border-radius: 10px;
}
.image-placeholder > div i {
  color: #6A86D9 !important;
}

.chapps-text-area textarea {
  border: none;
  background-color: var(--card-color) !important;
  color: #717171;
}

.hidden-sn main {
  padding-top: 0;
}

.side-nav {
  width: 20rem !important;
  box-shadow: none !important;
}

.h5-sub {
  font-size: 18px;
}

.mat-accordion .mat-expansion-panel {
  border-radius: 10px !important;
}

.mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0 15px !important;
}

.mat-accordion > .mat-expansion-panel-spacing:first-child, .mat-accordion > *:first-child:not(.mat-expansion-panel) .mat-expansion-panel-spacing {
  margin-top: 20px !important;
}

.truncated {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.profile-photo {
  background-color: var(--accent-1);
  color: white;
  width: 100%;
  height: 100%;
  padding: 0;
}

.logo {
  min-width: 112px;
  min-height: 112px;
  width: 112px;
  height: 112px;
  position: relative;
}
.logo .round {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.logo img {
  object-fit: cover;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #f5faff !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: #f5faff !important;
}

.selector-placeholder {
  background-color: var(--card-color) !important;
  border: none;
  padding: 0 !important;
}
.selector-placeholder div {
  background-color: var(--card-color) !important;
}
.selector-placeholder div .dropdown-menu {
  background-color: white !important;
}
.selector-placeholder div i {
  color: var(--accent-1) !important;
}

.message-input .form-control:-moz-focusring {
  color: none !important;
  text-shadow: none !important;
}

.mat-snack-bar-container {
  background: rgba(0, 22, 89, 0.9);
}

.btn {
  background: var(--accent-1);
  color: var(--card-color);
}

button.primary {
  background: var(--base-color-1);
  color: white;
}

.badge.badge-dot {
  background: var(--notification-color) !important;
}