.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-size-09 {
  font-size: 0.9rem !important;
}

.font-size-08 {
  font-size: 0.8rem !important;
}

label, textarea, p {
  // font-size: 0.9rem !important;
}

p {
  font-weight: 400;
  font-size: 0.9rem;
}

.no-wrap {
  white-space: nowrap;
}
