.trash-icon-in-table {
  position: relative;
  top: -1px;
}

.svg-black {
  .cls-1 {
    fill: black !important;
  }
}

/*.svg-secondary-text {
  .cls-1, path {
    fill: white !important;
  }
}*/
